import React from "react";
import { styled } from '@mui/material/styles';
import BackgroundImage from "../../Graphics/website_background.png"
import TrebleClef from "../../Graphics/trebleClef.png"
const PREFIX = 'Background';

const classes = {
    container: `${PREFIX}-container`,
    image: `${PREFIX}-image`,
    trebleClef: `${PREFIX}-trebleClef`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.container}`]: {
        width: "100%",
        height: "100%",
        position: "absolute",
        overflow: "hidden",
        top: 0, left: 0,
        zIndex: -1,
    },

    [`& .${classes.image}`]: {
        width: "100%",
        position: "absolute",
        top: 0, left: 0,
        [theme.breakpoints.down('lg')]: {
            transform: "scale(1.4)",
            transformOrigin: "top left"
        },
    },

    [`& .${classes.trebleClef}`]: {
        position: "fixed",
        overflow: "hidden",
        right: -30,
        top: 250,
        maxWidth: "20%",
        opacity: "0.3"
    }
}));


export default function Background() {



    return (
        <Root style={{maxWidth: "100vw", overflow: "hidden"}}>
            <div className={classes.container}>
                <img src={BackgroundImage}
                     alt="Background"
                     className={classes.image}
                />
            </div>
            <div style={{overflow: "hidden"}}>
                <img src={TrebleClef}
                     alt="Treble Clef"
                     className={classes.trebleClef}
                />
            </div>
        </Root>
    );
}