import React from "react";

import { styled } from '@mui/material/styles';

import MusicVideos from "./PageElements/MusicVideos";

import "./Pages.css";
import {Grid, Typography} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {Helmet} from "react-helmet";
import useMediaQuery from "@mui/material/useMediaQuery";


const PREFIX = 'MusicPage';

const classes = {
    text: `${PREFIX}-text`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.text}`]: {
        flexGrow: 1,
        textAlign: "center",
        display: "block",
        alignSelf: "center",
        padding: "20px",
        [theme.breakpoints.down('md')]: {
            maxWidth: "100%",
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: "calc(217px + 32vw)",
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: "left",
        },
    }
}));

export default function MusicPage() {



    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Root
            className="page"
            style={{
                display: "flex",
                flexDirection: 'column',
                justifyContent: "center",
                minHeight: "calc(100vh - 70px - 180px)",
            }}
        >
            <Helmet>
                <title>Music</title>
                <meta name="description"
                      content="Have a listen to some of my work."/>
            </Helmet>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item className={classes.text}>
                    <div>
                        <Typography variant="h4">Music</Typography>
                        <Typography variant="h6" style={isMobileScreen ? {textAlign: "justify"} : {textAlign: "left"}}>Listen
                            to some of my music and let me know how you feel about them.
                            It is always a pleasure to get some feedback. Feel free to check out my Youtube and
                            Spotify channels to get updates about my music and connect with me on social media to
                            get all updates about my other projects.</Typography>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{marginBottom: "70px", display: "flex"}}>
                <MusicVideos/>
            </Grid>
        </Root>
    );
}