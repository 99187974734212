import React from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import {ReactComponent as YouTubeLogo} from '../../Graphics/YouTubeLogo.svg';
import {ReactComponent as SpotifyLogo} from '../../Graphics/SpotifyLogo.svg';
import {ReactComponent as FacebookLogo} from '../../Graphics/FBLogo.svg';


const PREFIX = 'Footer';

const classes = {
    root: `${PREFIX}-root`,
    button: `${PREFIX}-button`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        flexGrow: 1,
        width: "100%",
        height: 70,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },

    [`& .${classes.button}`]: {
        color: "#ece9e9",
        backgroundColor: "#ffffff30",
        display: "flex",
        alignItems: "center",
        fontFamily: "Arial",
        border: "solid #00000040 3px",
        opacity: "0.6"
    }
}));


export default function AutoGrid() {



    return (
        <Root className={classes.root}>
            <Grid
                container
                spacing={0}
                alignContent="center"
                justifyContent="center"
                direction="column">
                <Grid container item xs={11} spacing={1}>
                    <Grid item xs>
                        <Button
                            fullWidth
                            className={classes.button}
                            // style={{backgroundColor: "#b23c3b"}}
                            variant="contained"
                            startIcon={<YouTubeLogo/>}
                            rel="noopener noreferrer"
                            href="https://www.youtube.com/channel/UCYhNfoWG-o-hF2mRCvZfXzg?view_as=subscriber?sub_confirmation=1"
                            target="_blank"
                        >
                            Youtube
                        </Button>
                    </Grid>

                    <Grid item xs>
                        <Button
                            fullWidth
                            className={classes.button}
                            // style={{backgroundColor: "#43a346"}}
                            variant="contained"
                            startIcon={<SpotifyLogo/>}
                            rel="noopener noreferrer"
                            href="https://open.spotify.com/artist/5E6HJ6odyVc2ksB41JrPsZ?si=nO64RxStQk-t_5DxtGwITA"
                            target="_blank"
                        >
                            Spotify
                        </Button>
                    </Grid>

                    <Grid item xs>
                        <Button
                            fullWidth
                            className={classes.button}
                            // style={{backgroundColor: "#1168b1"}}
                            variant="contained"
                            startIcon={<FacebookLogo/>}
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/sean.a.s.mengis/"
                            target="_blank"
                        >
                            Facebook
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Root>
    );
}