import {styled} from "@mui/material/styles";
import {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";

const Root = styled("div")(({theme}) => ({
    position: "absolute",
    top: 0,
    padding: theme.spacing(5),
    backgroundColor: "rgba(231,231,231,0.97)",
    width: "100%",
    zIndex: 500,
}));

export default function () {

    const [isDisplayed, setDisplayed] = useState(false);

    function agreeToCookies() {
        localStorage.setItem("cookie-agreement", "agreed");
        setDisplayed(false);
    }

    useEffect(() => {
        const hasAgreed = localStorage.getItem("cookie-agreement");
        if (hasAgreed !== "agreed") {
            setDisplayed(true);
        }
    }, [])

    return <Root style={isDisplayed ? {opacity: 1, visibility: "visible"} : {opacity: 0, visibility: "hidden"}}>
        <Typography>By using this page you agree to the use of cookies which are used by the embedded YouTube videos on the music page :) Apart from that your IP-Address you accessed the Webpage with is temporarily stored in log files as well as access time and date.</Typography>
        <Button onClick={agreeToCookies} variant="contained">Agree!</Button>
    </Root>
}