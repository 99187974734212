import React from 'react';

import AboutPageContent from './PageElements/AboutPageContent';

import "./Pages.css";
import {Helmet} from "react-helmet";
import {useMediaQuery, useTheme} from "@mui/material";

import Profilephoto from "../Graphics/Profilephoto.jpg";


const aboutData = {
    image: Profilephoto,
    title: "About",
    description: "I was born in the region of Basle, Switzerland, where I went to school" +
    "and lived my days as a kid. At the age of 15 I went to a gardening school in" +
    "Geneva that I successfully finished. Taking my experience as a gardener I did" +
    "my civil service in organizations such as ProNatura that focus on taking care" +
    "of the environment. During that time I traveled to Japan where I tried to learn" +
    "the language. Japan was the key point where I discovered my passion for making" +
    "music. After coming back home I decided that I wanted to attend a composing" +
    "school in Zurich and took necessary classes to prepare for the entrance exams." +
    "Sadly, even though I succeeded the entrance exams twice I couldn't attend the" +
    "school because they didn't have enough free places. But it was not all in vain." +
    "All the knowledge I amassed trough those two to three years helps me immensely" +
    "when composing. Nevertheless the exam experts strongly encouraged" +
    "me to pursue my dream of making music professionally. Now, to earn myself a living" +
    "I took up studies to become a programmer."
}

export default function AboutPage() {

    const theme = useTheme();

    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

        return (
            <div
                className="page"
                style={isMobileScreen ? {marginTop: "30px"} : {marginTop: "60px"}}
            >
                <Helmet>
                    <title>About me</title>
                    <meta name="description"
                          content="Find out who I am and what I do."/>
                </Helmet>
                <div style={{padding: "10px"}}>
                    <AboutPageContent about={aboutData}/>
                </div>
            </div>
        )
}