import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
    palette: {
        primary: {
            light: "#687f86",
            main: "#3d5359",
            dark: "#152a30",
            contrastText: "#f5f5f5",

        },
        secondary: {
            light: "#7cffff",
            main: "#37d7ef",
            dark: "#00a5bc",
            contrastText: "#262626",
        },
        background: {
            default: "#000000"
        }
    },
    typography: {
        fontFamily: "sans-serif"
    },
});

export default Theme;