import React, {FunctionComponent} from "react";
import { styled } from '@mui/material/styles';
import {Grid, Typography} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {About} from "../../Services/Models";
import useMediaQuery from "@mui/material/useMediaQuery";


const PREFIX = 'AboutPageContent';

const classes = {
    root: `${PREFIX}-root`,
    img: `${PREFIX}-img`,
    imgPos: `${PREFIX}-imgPos`,
    textGrid: `${PREFIX}-textGrid`,
    textContainer: `${PREFIX}-textContainer`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        flexGrow: 1,
    },

    [`& .${classes.img}`]: {
        position: "absolute",
        top: "50%", left: "50%",
        borderRadius: "3px",
        height: 550,
        width: "auto",
        margin: "-275px 0 0 -207px",
        [theme.breakpoints.down('lg')]: {
            height: 400,
            margin: "-200px 0 0 -80px"
        },
        [theme.breakpoints.down('md')]: {
            // margin: "0px 0 0 -150.5px"
            position: "relative"
        },
        [theme.breakpoints.down('sm')]: {
            // margin: "0px 0 0 -150.5px"
            position: "relative",
            height: 300,
            margin: 0,
            top: 0, left: 0,
        },

    },

    [`& .${classes.imgPos}`]: {
        position: "relative",
        [theme.breakpoints.down('lg')]: {
            height: 400,
        },
        [theme.breakpoints.down('sm')]: {
            position: "relative",
            height: 300,
            display: "flex",
            justifyContent: "center"
        },
    },

    [`& .${classes.textGrid}`]: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        margin: "10px",
    },

    [`& .${classes.textContainer}`]: {
        padding: "5vh",
        margin: "40px",
        marginTop: "40px",
        [theme.breakpoints.down('sm')]: {
            margin: "0",
            padding: "0"
        }
    }
}));

const AboutPageContent: FunctionComponent<{ about: About }> = props => {



    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Root className={classes.root}>
            <Grid container>
                <Grid item xs={12} lg={4} className={classes.imgPos}>
                    <img src={props.about.image} alt="Not available"
                         className={classes.img}/>
                </Grid>
                <Grid item xs={12} lg={7} className={classes.textGrid}>
                    <div className={classes.textContainer}>
                        <Typography variant="h4" >
                            {props.about.title}
                        </Typography>
                        <Typography variant="h6" style={isMobileScreen ? {textAlign: "justify"} : {textAlign: "left"}}>
                            {props.about.description}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Root>
    );
}
export default AboutPageContent;
