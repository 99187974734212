import React from "react";
import { styled } from '@mui/material/styles';
import {Grid, Typography} from "@mui/material";
import Navigation from "./Navigation";

import "../../index.css";

const PREFIX = 'Header';

const classes = {
    banner: `${PREFIX}-banner`,
    titlePos: `${PREFIX}-titlePos`,
    title: `${PREFIX}-title`,
    subtitle: `${PREFIX}-subtitle`,
    listItem: `${PREFIX}-listItem`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.banner}`]: {
        width: "100%",
        maxHeight: 180,
        display: "block",
        position: "absolute",
        overflow: "hidden",
        objectFit: "cover",
        top: 0, left: 0,
        zIndex: 10,
        opacity: 0
    },

    [`& .${classes.titlePos}`]: {
        position: "absolute",
        top: 40, left: 70,
        zIndex: 50,
        [theme.breakpoints.down('md')]: {
            top: 10,
            left: 17
        }
    },

    [`& .${classes.title}`]: {
        color: "#f5f5f5",
        fontFamily: "RUM",
        fontSize: 65,
        lineHeight: "1.2",
        [theme.breakpoints.down('lg')]: {
            fontSize: 55
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 40
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 20
        }
    },

    [`& .${classes.subtitle}`]: {
        color: "#f5f5f5",
        fontFamily: "RUM",
        fontSize: 32,
        lineHeight: "1.2",
        [theme.breakpoints.down('lg')]: {
            fontSize: 29
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 26
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 11
        }
    },

    [`& .${classes.listItem}`]: {
        display: "flex",
        justifyContent: "center"
    }
}));

export default function Header() {

    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            setLoaded(true)
        }, 500)
    })

    return (
        <Root style={{position: "relative", height: 180, width: "100%"}}>
            <Grid container direction="row">
                <Grid item xs={8}>
                    <div className={classes.titlePos}>
                        <Typography className={classes.title} style={loaded ? {transition: "3s"} : {transform: "translate(50vw, 0)", opacity: 0}}>Sean A.S. Mengis</Typography>
                        <Typography className={classes.subtitle} style={loaded ? {transition: "5s"} : {transform: "translate(50vw, 0)", opacity: 0}}>Welcome to my world!</Typography>
                    </div>
                </Grid>
                <Grid item xs={4} style={{display: "flex", justifyContent: "flex-end"}}>
                    <Navigation/>
                </Grid>
            </Grid>
        </Root>
    );
}
