import React, {FunctionComponent, useState} from "react";
import { styled } from '@mui/material/styles';
import {Drawer, Fab, Hidden, List, ListItem, ListItemButton, Typography} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';


const PREFIX = 'Navigation';

const classes = {
    list: `${PREFIX}-list`,
    listItem: `${PREFIX}-listItem`,
    drawer: `${PREFIX}-drawer`,
    fab: `${PREFIX}-fab`
};

const Root = styled('div')(({theme}) => ({
    display: "contents",

    [`& .${classes.list}`]: {
        color: "#f5f5f5",
        margin: "10px 25px 0 0",
        display: "flex",
        [theme.breakpoints.down('lg')]: {
            display: "block",
            color: "#000000",
        }
    },

    [`& .${classes.listItem}`]: {
        display: "flex",
        justifyContent: "center",
        margin: "10px 30px 10px 30px",
        [theme.breakpoints.down('lg')]: {
            margin: "unset"
        }
    },

    [`& .${classes.drawer}`]: {
        height: "110%",
        backgroundColor: "#000"
    },

    [`& .${classes.fab}`]: {
        background: "radial-gradient(circle, rgba(0,0,0,1) 12%, rgba(0,0,0,0) 77%)",
        position: "fixed"
    }
}));

const CustomListItem: FunctionComponent<{ title: string, link: string }> = props => {

    const location = useLocation();
    const isActive = location.pathname === props.link;

    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    if (isActive) {
        return (
            <ListItemButton component={Link} to={props.link} className={classes.listItem} onClick={scrollToTop}>
                <Typography>
                    <strong>{props.title}</strong>
                </Typography>
            </ListItemButton>
        )
    } else {
        return (
            <ListItemButton component={Link} to={props.link} className={classes.listItem} onClick={scrollToTop}>
                <Typography>
                    {props.title}
                </Typography>
            </ListItemButton>
        )
    }

}

export default function Navigation() {

    const [openDrawer, setOpenDrawer] = useState(false);

    const toggleDrawer = () => {
        if (!openDrawer) {
            setOpenDrawer(true);
        } else {
            setOpenDrawer(false);
            window.scrollTo(0, 0);
        }
    };

    return (
        <Root>
            <Hidden mdUp>
                <Fab onClick={toggleDrawer} className={classes.fab}>
                    <MenuIcon style={{color: "#f5f5f5"}}/>
                </Fab>
                <Drawer anchor="right"
                        open={openDrawer}
                        onClose={toggleDrawer}
                        disableScrollLock
                        classes={{
                            paper: classes.drawer,
                        }}
                        PaperProps={{style: {backgroundColor: "#f5f5f5"}}}
                >
                    <List className={classes.list}>
                        <CustomListItem title="Home" link="/home"/>
                        <CustomListItem title="Music" link="/music"/>
                        <CustomListItem title="Projects" link="/projects"/>
                        <CustomListItem title="About" link="/about"/>
                    </List>
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <List className={classes.list}>
                    <CustomListItem title="Home" link="/home"/>
                    <CustomListItem title="Music" link="/music"/>
                    <CustomListItem title="Projects" link="/projects"/>
                    <CustomListItem title="About" link="/about"/>
                </List>
            </Hidden>
        </Root>
    );
}