import React from 'react';
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Theme from "./Theme";
import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";
import {CssBaseline} from "@mui/material";

import Header from "./Pages/PageElements/Header";
import Footer from "./Pages/PageElements/Footer";

import HomePage from "./Pages/HomePage";
import MusicPage from "./Pages/MusicPage";
import ProjectsPage from "./Pages/ProjectsPage";
import AboutPage from "./Pages/AboutPage";
import Background from "./Pages/PageElements/Background";
import CookieBanner from "./CookieBanner";


export default function App() {

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={Theme}>
                <HashRouter>
                    <CssBaseline/>
                    <Background/>
                    <Header/>
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/home" element={<HomePage/>}/>
                        <Route path="/music" element={<MusicPage/>}/>
                        <Route path="/projects" element={<ProjectsPage/>}/>
                        <Route path="/about" element={<AboutPage/>}/>
                        <Route path="/*" element={<HomePage/>}/>
                    </Routes>
                    <Footer/>
                    <CookieBanner/>
                </HashRouter>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
