import React from 'react';
import { styled } from '@mui/material/styles';
import {Grid, Typography} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {Helmet} from "react-helmet";

import "./Pages.css";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

const PREFIX = 'HomePage';

const classes = {
    root: `${PREFIX}-root`,
    imgPos: `${PREFIX}-imgPos`,
    textGrid: `${PREFIX}-textGrid`,
    textContainer: `${PREFIX}-textContainer`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        flexGrow: 1,
        color: "#f5f5f5",
        minHeight: "calc(100vh - 70px - 180px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },

    [`& .${classes.imgPos}`]: {
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
    },

    [`& .${classes.textGrid}`]: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        margin: "20px",
    },

    [`& .${classes.textContainer}`]: {
        padding: 0,
    }
}));


export default function HomePage() {



    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Root className={classes.root}>
            <Helmet>
                <title>Sean A.S. Mengis</title>
                <meta name="description"
                      content="Welcome to my world of celtic and fantasy music."/>
            </Helmet>
            <Grid container>
                <Grid item xs={12} md={4} lg={5} className={classes.imgPos}/>
                <Grid item xs={12} md={6} lg={5} className={classes.textGrid}>
                    <div className={classes.textContainer}>
                        <Typography variant="h6" style={isMobileScreen ? {
                            marginBottom: "40px",
                            textAlign: "justify"
                        } : {marginBottom: "40px"}}>
                            <Box fontWeight="fontWeightRegular">
                                Happy you found the way to my website! If you are a friend of fantasy
                                and celtic music you've come to the right place. I welcome you to take a look
                                at my work and check out what I've made in the past and what current
                                projects I might be working on. Also if you have any feedback or want to connect
                                with me I'd be glad if you could send me a message on social media.
                            </Box>
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Root>
    );
}
