import React from 'react';
import {Grid, Link, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import "./Pages.css";
import {Projects} from "../Services/Models";
import {Helmet} from "react-helmet";

import ElliDudul from "../Graphics/ElliDudul.jpg";
import DLS from "../Graphics/DLS.png";
import MilasTagebuch from "../Graphics/Milas_Tagebuch.jpg";
import Himalaya from "../Graphics/Himalaya_1.jpg";


const projectData = [
    {
        image: ElliDudul,
        title: "Elli&Düdül",
        description: "This shortfilm is a documentary about an old swiss couple made by" +
                "Anja Stadelmann. The film tells you how they manage their daily life while the" +
                "husband is slowly getting dementia. Although they are aware of the difficulties" +
                "they are able to fully enjoy life and openly talk of their past and future.",
        link: "",
        linktext: "",
        year: 2020,
        even: 0,
        id: 0
    },
    {
        image: DLS,
        title: "Trailer - Der letzte Schrei",
        description: "Soundtrack for the trailer of the film Der letzte Schrei by Gabriel" +
                "da Silva. The film is revolving around difficult school life, mobbing, and love." +
                "Follow the story of Felix as he confronts all those situations.",
        link: "https://vimeo.com/365873191",
        linktext: "Watch on Vimeo",
        year: 2019,
        even: 1,
        id: 1
    },
    {
        image: MilasTagebuch,
        title: "Milas Tagebuch",
        description: "Milas Tagebuch is a shortfilm made by Gabriel da Silva. After getting" +
                "into a fight with some of her classmates Mila is questioned by her teacher on" +
                "her reasons. The little girl documented all that happened in a diary which she" +
                "hands to her teacher, triggering memories the teacher had during her own schooldays.",
        link: "https://vimeo.com/412124485",
        linktext: "Watch on Vimeo",
        year: 2019,
        even: 2,
        id: 2
    },
    {
        image: Himalaya,
        title: "Hanging by a thread",
        description: "A documentary made by Caroline Wloka. Five young men with a passion" +
                "for paragliding set out for an expedition in the Himalayas. Unknowing of the" +
                "life and death situation that awaits them...Raphael Sommer gave me the chance" +
                "to write music for my first film. We collaborated on two songs that were included" +
                "in the documentary.",
        link: "https://open.spotify.com/album/6Icl1R5eZNupaSTwlrKVwl?si=RQYmMpbMSTaz-8jxBP-gkg",
        linktext: "Listen on Spotify",
        year: 2018,
        even: 3,
        id: 3
    }
]

function ProjectLayout(props: Projects) {

    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const imageLayout = <Grid item xs={12} md={5} lg={4}
                              style={isMobileScreen ? {} : {maxWidth: "80vw", margin: 30}}>

        <img src={props.image} alt="Not available" style={{
            width: "100%",
            height: "auto",
            boxShadow: "2px 2px 6px #00000066",
            border: "solid #00000060 1px",
            transform: "scale(0.89)"
        }}/>
    </Grid>;

    const textLayout = <Grid item xs={12} md lg
                             style={isMobileScreen ? {} : {maxWidth: "80vw"}}>

        <Typography variant="h4" style={isMobileScreen ? {} : {padding: "65px 65px 0 65px"}}>{props.title}</Typography>
        <Typography variant="h6" style={isMobileScreen ? {textAlign: "justify"} : {display: "inline-block", padding: "0 65px 0 65px"}}>{props.description}</Typography>
        <Typography>
            <Link href={props.link} target="_blank" style={isMobileScreen ? {color: "#c7c4c4"} : {color: "#c7c4c4", padding: "0 65px 0 65px"}}>{props.linktext}</Link>
        </Typography>
        <Typography variant="subtitle1" style={isMobileScreen ? {} : {padding: "0 65px 0 65px"}}>{props.year}</Typography>
    </Grid>;

    const firstElement = isDesktopScreen ? (props.even ? imageLayout : textLayout) : imageLayout;
    const secondElement = isDesktopScreen ? (props.even ? textLayout : imageLayout) : textLayout;

    return (
        <Grid container style={{padding: "3vh"}}>
            {firstElement}
            {secondElement}
        </Grid>
    )
}


export default function ProjectsPage() {

    const theme = useTheme();

    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    function ProjectData(props: { project: Projects[] }) {
        const project = props.project;
        const items = project.map((project: Projects, key: number) =>
            <div key={key}>
                <ProjectLayout
                    id={project.id}
                    even={key % 2 === 0}
                    key={key}
                    image={project.image}
                    title={project.title}
                    description={project.description}
                    link={project.link}
                    linktext={project.linktext}
                    year={project.year}
                />
            </div>
        );
        return <>{items}</>
    }


        return (
            <div
                className="page"
                style={isMobileScreen ? {marginTop: 0} : {marginTop: "60px"}}
            >
                <Helmet>
                    <title>Projects</title>
                    <meta name="description"
                          content="Check out projects I've worked on and might be working on."/>
                </Helmet>
                <div className="projectData-container" style={{marginBottom: 40}}>
                    <ProjectData project={projectData}/>
                </div>
            </div>
        )
}