import React, {FunctionComponent, useState} from 'react';
import {styled} from '@mui/material/styles';
import ReactPlayer from "react-player";

import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMediaQuery from "@mui/material/useMediaQuery";

import {Music, TabPanelProps} from "../../Services/Models";

const PREFIX = 'MusicVideos';

const classes = {
    root: `${PREFIX}-root`,
    tabs: `${PREFIX}-tabs`,
    playerWrapper: `${PREFIX}-playerWrapper`,
    reactPlayer: `${PREFIX}-reactPlayer`
};

const Root = styled("div")((
    {
        theme
    }
) => ({
    display: "flex",
    flexGrow: 1,
    height: "17vw", // set to 16:9 aspect ration with playerWrapper width
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        marginBottom: "20px",
    },
    [theme.breakpoints.down('md')]: {
        marginBottom: "56.25vw",
    },

    [`& .${classes.tabs}`]: {
        backgroundColor: "#ffffff08",

        [theme.breakpoints.up('md')]: {
            height: "18vw",
        },
    },

    [`& .${classes.playerWrapper}`]: {
        position: "relative",
        paddingTop: "56.25%",
        //width: "32vw", // set to 16:9 aspect ration with root height nad tabs height
        [theme.breakpoints.up('md')]: {
            width: "32vw",
        },
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        boxShadow: "3px 3px 10px #00000066"
    },

    [`& .${classes.reactPlayer}`]: {
        position: "absolute",
        top: 0,
        left: 0,
    }
}));

function TabPanel(props: TabPanelProps) {

    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const ResponsivePlayer: FunctionComponent<{ url: string }> = props => {
    return (
        <div className={classes.playerWrapper}>
            <ReactPlayer
                className={classes.reactPlayer}
                url={props.url}
                width="100%"
                height="100%"
            />
        </div>
    );
}

const musicList = [
    {
        id: 0,
        title: "Autumn comes",
        link: "https://www.youtube.com/watch?v=L3DGm3UGXnI"
    },
    {
        id: 1,
        title: "Forgotten Castle",
        link: "https://www.youtube.com/watch?v=atTKamG6-io"
    },
    {
        id: 2,
        title: "The Gods Legacy",
        link: "https://www.youtube.com/watch?v=xnSvvO18MMs"
    },
    {
        id: 3,
        title: "Tranquility",
        link: "https://www.youtube.com/watch?v=vb6sTvT8mRM"
    },
    {
        id: 4,
        title: "Limitless Skies",
        link: "https://www.youtube.com/watch?v=nSaN478kGLo"
    },
    {
        id: 5,
        title: "Homeland",
        link: "https://www.youtube.com/watch?v=G-LswvhonLw"
    },
    {
        id: 6,
        title: "Greeting the Light of Day",
        link: "https://www.youtube.com/watch?v=lbg5juNWieY"
    },
    {
        id: 7,
        title: "Full Throttle",
        link: "https://www.youtube.com/watch?v=6IH8ntYVFUI"
    },
    {
        id: 8,
        title: "Rivers Melancholy",
        link: "https://www.youtube.com/watch?v=B6jIOB_97Fk"
    },
    {
        id: 9,
        title: "Creatures of the Night",
        link: "https://www.youtube.com/watch?v=94lwvU1Z0-0"
    },
]


export default function MusicVids() {

    const theme = useTheme();

    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));

    const [value, setValue] = useState(0);

    function MusicTab(props: { key: number, label: string }) {
        return <Tab value={value} {...props} style={{color: "white"}}/>
    }

    function MusicTabpanel(props: { music: Music[], value: number }) {
        const music = props.music;
        const listItems = music.map((music: Music) =>
            <TabPanel key={music.id} value={value} index={music.id}>
                <ResponsivePlayer url={music.link}/>
            </TabPanel>
        );
        return (
            <>{listItems}</>
        );
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Root className={classes.root}>
            <Tabs
                TabIndicatorProps={{
                    style: {
                        backgroundColor: '#65cfee',
                    }
                }}
                orientation={isDesktopScreen ? "vertical" : "horizontal"}
                variant="scrollable"
                value={value}
                onChange={handleChange}
                className={classes.tabs}
            >
                {musicList.map((data: Music) =>
                    <MusicTab key={data.id} label={data.title} {...a11yProps(data.id)} />
                )}
            </Tabs>

            <MusicTabpanel value={value} music={musicList}/>
        </Root>

    );
}
